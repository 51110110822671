import { defineNuxtPlugin, useRuntimeConfig } from '#app'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  // Say hi to whoever opens the console
  console.log(
    '%cRADIO\u00A0\u00A0\n%c     BRONY\u00A0\u00A0',
    'margin-bottom:-15px;color:#a3d7ee;-webkit-text-stroke:1px #79b3d1;text-stroke:1px #79b3d1;font-weight:bold;font-size:50px;font-family:"GROBOLD","Helvetica Neue","Helvetica","Arial",sans-serif;',
    'color:#cb313c;-webkit-text-stroke:1px #a71721;text-stroke:1px #a71721;font-weight:bold;font-size:50px;font-family:"GROBOLD","Helvetica Neue","Helvetica","Arial",sans-serif;'
  )
  console.log(
    '%cGit: https://git.rita.moe/RadioBrony/rb-nuxt' +
    '\nVersion: ' + config.public.gitVersion +
    '\nEnv: ' + import.meta.env.MODE,
    'color:green;'
  )
})
