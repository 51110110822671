<template>
  <div id="app">
    <div class="error-page">
      <h2>Oh non, une erreur !</h2>
      <img src="@/assets/derpy.gif">
      <p>
        Quelque chose ne semble pas correct par ici, une erreur est survenue.<br/>
        Veuillez réessayer dans quelques instants...
      </p>
      <p>
        Si le problème persiste, veuillez nous contacter
        <a target="_blank" rel="noopener noreferrer" href="https://equestria.social/@RadioBrony">
          sur le Fedivers</a>
        ou prévenez <code>@0kody</code>
        <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/MdhuDym">
          sur notre Discord</a> !
      </p>
      <p>
        Message d'erreur : <code><pre>{{ props.error?.message || (props.error?.statusCode ?? '(aucun)') }}</pre></code>
      </p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  error: Object
})

useHead({
  title: 'Oh non, une erreur !'
})
</script>

<style lang="scss">
// App styles
@import '@/assets/app';
// Dark overrides
@import '@/assets/dark-app';

.error-page {
  text-align: center;
}
</style>
