import { default as _91_46_46_46slug_93khe9jL31r2Meta } from "/var/www/rb-nuxt-test/pages/[...slug].vue?macro=true";
import { default as bandcamp_45friday1X1Pso7NkrMeta } from "/var/www/rb-nuxt-test/pages/bandcamp-friday.vue?macro=true";
import { default as dossier_45comment_45et_45ou_45trouver_45de_45la_45musique_45brony_45aujourdhuiwbQnTo4rVYMeta } from "/var/www/rb-nuxt-test/pages/dossier-comment-et-ou-trouver-de-la-musique-brony-aujourdhui.vue?macro=true";
import { default as equipe9dkUroTp28Meta } from "/var/www/rb-nuxt-test/pages/equipe.vue?macro=true";
import { default as faqyRrYqjtzo7Meta } from "/var/www/rb-nuxt-test/pages/faq.vue?macro=true";
import { default as friendship_45is_45magic_45ce_45que_45pourraient_45apprendre_45de_45mlp_45les_45artistes_45independantsVMsElUiUGXMeta } from "/var/www/rb-nuxt-test/pages/friendship-is-magic-ce-que-pourraient-apprendre-de-mlp-les-artistes-independants.vue?macro=true";
import { default as indexC8VwMmYPobMeta } from "/var/www/rb-nuxt-test/pages/index.vue?macro=true";
import { default as liveIQEuLG4hfMMeta } from "/var/www/rb-nuxt-test/pages/live.vue?macro=true";
import { default as indexL6bovdy15uMeta } from "/var/www/rb-nuxt-test/pages/podcasts/index.vue?macro=true";
import { default as _91ep_93XXWiNmJm2IMeta } from "/var/www/rb-nuxt-test/pages/podcasts/show/[show]/episode/[se]/[ep].vue?macro=true";
import { default as indexasvM4F3koRMeta } from "/var/www/rb-nuxt-test/pages/podcasts/show/[show]/index.vue?macro=true";
import { default as podcastsb0rGiHbOzCMeta } from "/var/www/rb-nuxt-test/pages/podcasts.vue?macro=true";
import { default as privacyXpydoDGL6CMeta } from "/var/www/rb-nuxt-test/pages/privacy.vue?macro=true";
import { default as component_45stubEUDhJKAiRkMeta } from "/var/www/rb-nuxt-test/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.13_ioredis@5.4.1_magicast@0.3.4_rollup@4._3q5uvwrfnj7pipasqw475ps63q/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubEUDhJKAiRk } from "/var/www/rb-nuxt-test/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.13_ioredis@5.4.1_magicast@0.3.4_rollup@4._3q5uvwrfnj7pipasqw475ps63q/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93khe9jL31r2Meta || {},
    component: () => import("/var/www/rb-nuxt-test/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "bandcamp-friday",
    path: "/bandcamp-friday",
    component: () => import("/var/www/rb-nuxt-test/pages/bandcamp-friday.vue").then(m => m.default || m)
  },
  {
    name: "dossier-comment-et-ou-trouver-de-la-musique-brony-aujourdhui",
    path: "/dossier-comment-et-ou-trouver-de-la-musique-brony-aujourdhui",
    meta: dossier_45comment_45et_45ou_45trouver_45de_45la_45musique_45brony_45aujourdhuiwbQnTo4rVYMeta || {},
    component: () => import("/var/www/rb-nuxt-test/pages/dossier-comment-et-ou-trouver-de-la-musique-brony-aujourdhui.vue").then(m => m.default || m)
  },
  {
    name: "equipe",
    path: "/equipe",
    meta: equipe9dkUroTp28Meta || {},
    component: () => import("/var/www/rb-nuxt-test/pages/equipe.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqyRrYqjtzo7Meta || {},
    component: () => import("/var/www/rb-nuxt-test/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "friendship-is-magic-ce-que-pourraient-apprendre-de-mlp-les-artistes-independants",
    path: "/friendship-is-magic-ce-que-pourraient-apprendre-de-mlp-les-artistes-independants",
    meta: friendship_45is_45magic_45ce_45que_45pourraient_45apprendre_45de_45mlp_45les_45artistes_45independantsVMsElUiUGXMeta || {},
    component: () => import("/var/www/rb-nuxt-test/pages/friendship-is-magic-ce-que-pourraient-apprendre-de-mlp-les-artistes-independants.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/rb-nuxt-test/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "live",
    path: "/live",
    meta: liveIQEuLG4hfMMeta || {},
    component: () => import("/var/www/rb-nuxt-test/pages/live.vue").then(m => m.default || m)
  },
  {
    name: podcastsb0rGiHbOzCMeta?.name,
    path: "/podcasts",
    component: () => import("/var/www/rb-nuxt-test/pages/podcasts.vue").then(m => m.default || m),
    children: [
  {
    name: "podcasts",
    path: "",
    meta: indexL6bovdy15uMeta || {},
    component: () => import("/var/www/rb-nuxt-test/pages/podcasts/index.vue").then(m => m.default || m)
  },
  {
    name: "podcasts-show-show-episode-se-ep",
    path: "show/:show()/episode/:se()/:ep()",
    component: () => import("/var/www/rb-nuxt-test/pages/podcasts/show/[show]/episode/[se]/[ep].vue").then(m => m.default || m)
  },
  {
    name: "podcasts-show-show",
    path: "show/:show()",
    component: () => import("/var/www/rb-nuxt-test/pages/podcasts/show/[show]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyXpydoDGL6CMeta || {},
    component: () => import("/var/www/rb-nuxt-test/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: component_45stubEUDhJKAiRkMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubEUDhJKAiRk
  },
  {
    name: component_45stubEUDhJKAiRkMeta?.name,
    path: "/sitemaps",
    component: component_45stubEUDhJKAiRk
  }
]