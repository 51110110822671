import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp
  const config = useRuntimeConfig()

  if (!process.dev) {
    console.log('Sentry enabled, ready to catch bugs 🐛')

    Sentry.init({
      app: [ vueApp ],
      dsn: 'https://9231e6886fdd43c68c57c9d6424f7057@o146961.ingest.sentry.io/6769865',
      environment: (
        process.env.NODE_ENV === 'production' && config.public.gitBranch === 'live'
          ? 'production'
          : 'development'
      ), // Set environment
      integrations: [
        Sentry.browserTracingIntegration({
          // @ts-expect-error Trust me, it's the router
          router: nuxtApp.$router
        })
      ],
      logErrors: false, // Note that this doesn't seem to work with nuxt 3
      release: config.public.gitVersion,
      tracePropagationTargets: [
        /https:\/\/(www\.)?radiobrony\./,
        'https://test.radiobrony.fr'
      ],
      tracesSampleRate: 1.0,
      tunnel: '/sen',

      // The following enables exeptions to be logged to console despite logErrors being set to false (preventing them from being passed to the default Vue err handler)
      beforeSend (event, hint) {
        // Check if it is an exception, and if so, log it
        if (event.exception) {
          console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, { event, hint })
        }
        // Continue sending to Sentry
        return event
      },
    })

    vueApp.mixin(
      Sentry.createTracingMixins({
        trackComponents: true,
        timeout: 2000,
        hooks: [ 'activate', 'mount', 'update' ]
      })
    )
    Sentry.attachErrorHandler(
      vueApp,
      {
        logErrors: false,
        attachProps: true,
        trackComponents: true,
        timeout: 2000,
        hooks: [ 'activate', 'mount', 'update' ]
      }
    )

    return {
      provide: {
        sentrySetContext: (n: string, context: { [key: string]: any; } | null) => Sentry.setContext(n, context),
        sentrySetUser: (user: Sentry.User | null) => Sentry.setUser(user),
        sentrySetTag: (tagName: string, value: any) => Sentry.setTag(tagName, value),
        sentryAddBreadcrumb: (breadcrumb: Sentry.Breadcrumb) => Sentry.addBreadcrumb(breadcrumb)
      }
    }
  } else {
    return {
      provide: {
        sentrySetContext: () => { return false },
        sentrySetUser: () => { return false },
        sentrySetTag: () => { return false },
        sentryAddBreadcrumb: () => { return false }
      }
    }
  }
})