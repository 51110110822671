<template>
  <div id="app" :class="{ 'dark-app': store.userConfig.darkApp === true }">
    <header class="main-header">
      <nav class="full-nav">
        <NuxtLink
          v-for="n in navBar"
          :key="n.path"
          :class="n.class"
          :to="n.path">
          {{ n.title }}
        </NuxtLink>
      </nav>

      <select
        v-model="currentNav"
        class="mobile-nav"
        @change="$router.push(currentNav)">
        <option disabled selected value="">-- Navigation --</option>
        <option
          v-for="n in navBar"
          :key="n.path"
          :value="n.path">
          {{ n.title }}
        </option>
      </select>

      <div
        v-if="offline"
        class="offline">
        <strong>Il semblerait que vous êtes déconnecté d'Internet.</strong><br>
        Certains éléments risquent de ne pas charger correctement.
      </div>

      <Player/>
    </header>

    <main>
      <NuxtPage/>
    </main>

    <footer class="main-footer">
      <hr/><br/>
      &copy; Radio Brony (2012-{{ (new Date()).getFullYear() }})<br/>
      <NuxtLink to="/faq">FAQ</NuxtLink>
      -
      <NuxtLink to="/faq#music-credits">Crédits</NuxtLink>
      -
      <NuxtLink to="/equipe">Équipe</NuxtLink>
      -
      <NuxtLink to="/privacy">Vie Privée</NuxtLink><br/>
      <a
        href="#"
        @click="toggleDarkApp">
        {{ store.userConfig.darkApp ? 'Allumer' : 'Éteindre' }} la lumière</a>
      <br/><br/>
      <a
        target="_blank"
        rel="noreferrer noopener"
        href="https://web3isgoinggreat.com">
        <img
          alt="This is an Anti-NFT Site"
          class="clipart"
           width="88" height="31"
          src="@/assets/antinft.clip.gif">
      </a>
      <img
        alt="made with affection"
        class="clipart"
         width="88" height="31"
        src="@/assets/affection.clip.gif"
        @click="popHearts">
      <img
        alt="Linux powered"
        class="clipart"
         width="88" height="31"
        src="@/assets/linuxpowered.clip.gif"
        @click="toggleVM">
    </footer>
  </div>

  <div class="h-card" style="display: none;">
    <img class="u-logo u-photo" src="https://radiobrony.fr/img/icons/bwavatar-details.png" />
    <span class="p-name">Radio Brony</span>
    <span class="p-note">La webradio des Bronies, 20% plus baguette</span>
    <a rel="me" class="u-url" href="https://radiobrony.fr">Website</a>
    <a class="u-email" href="mailto:contact@radiobrony.fr">Email</a>
  </div>

  <BootLinux v-if="startVM"/>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { DateTime } from 'luxon'
import { useStore } from '~/store/store'

const route = useRoute()
const store = useStore()
// @TODO: Remove this in 2024
const oldDarkApp = useCookie('darkApp', {
  sameSite: true
})

const currentNav = ref('')
const bodyClasses = ref('')
const navBar = ref([
  {
    title: 'Accueil',
    path: '/',
    class: 'home-link'
  },
  {
    title: 'Live',
    path: '/live',
    class: null
  },
  {
    title: 'Podcasts',
    path: '/podcasts',
    class: null
  },
  {
    title: 'FAQ',
    path: '/faq',
    class: null
  }
])
const offline = ref(false)
const startVM = ref(false)

const menuSelectUpdate = () => {
  const isInMenu = navBar.value.some((n) => {
    return n.path === '/' + route.path.split('/')[1]
  })

  if (isInMenu) currentNav.value = '/' + route.path.split('/')[1]
  else currentNav.value = ''
}

const toggleDarkApp = (e) => {
  e.preventDefault()
  store.userConfig.darkApp = !store.userConfig.darkApp

  // Set theme for Twitter widgets
  const m = document.querySelector('meta[name="twitter:widgets:theme"]')
  m.setAttribute('content', (store.userConfig.darkApp === true ? 'dark' : 'light'))
}

const dayOrNight = async () => {
  // Add or remove 'night' class based on the time
  const now = DateTime.local().setZone('Europe/Paris')

  // night in winter: 17~8
  let sunrise = 8
  let sunset = 17
  if (now.isInDST) {
    // night in summer: 20~7
    sunrise = 7
    sunset = 20
  }

  if (now.hour < sunrise || now.hour >= sunset) {
    bodyClasses.value = 'night'
  } else {
    bodyClasses.value = 'day'
  }

  if (now.month === 10) { // October
    store.userConfig.darkApp = true
    bodyClasses.value += ' halloween'
  } else if (now.month === 12) { // December
    bodyClasses.value += ' xmas'

    if (process.client) {
      const snowf = await import('snowf')
      const snow = snowf.init({
        onWindow: true,
        amount: 69,
        size: 5,
        zIndex: -1
      })
      window.onresize = () => {
        snow.resize()
      }
    }
  }
}

const popHearts = (e) => {
  // From https://css-tricks.com/playing-with-particles-using-the-web-animations-api/
  if (!document.body.animate) return
  const amount = 30

  // Quick check if user clicked the button using a keyboard
  if (e.clientX === 0 && e.clientY === 0) {
    const bbox = e.target.getBoundingClientRect()
    const x = bbox.left + bbox.width / 2
    const y = bbox.top + bbox.height / 2

    for (let i = 0; i < 30; i++) {
      // We call the function createParticle 30 times
      // We pass the coordinates of the button for x & y values
      createParticle(x, y, e.target.dataset.type)
    }
  } else {
    for (let i = 0; i < amount; i++) {
      // console.log(e)
      createParticle(e.clientX, e.clientY, e.target.dataset.type)
    }
  }

  function createParticle (x, y, type) {
    const particle = document.createElement('particle')
    document.body.appendChild(particle)
    const destinationX = (Math.random() - 0.5) * 300
    const destinationY = (Math.random() - 0.5) * 300
    const rotation = Math.random() * 520
    const delay = Math.random() * 200

    particle.innerHTML = ['❤', '🧡', '💛', '💚', '💙', '💜', '🤎'][Math.floor(Math.random() * 7)]
    particle.style.fontSize = `${Math.random() * 24 + 10}px`

    const animation = particle.animate([
      {
        transform: `translate(-50%, -50%) translate(${x}px, ${y}px) rotate(0deg)`,
        opacity: 1
      },
      {
        transform: `translate(-50%, -50%) translate(${x + destinationX}px, ${y + destinationY}px) rotate(${rotation}deg)`,
        opacity: 0
      }
    ], {
      duration: Math.random() * 1000 + 5000,
      easing: 'cubic-bezier(0, .9, .57, 1)',
      delay
    })

    animation.onfinish = removeParticle
  }

  function removeParticle (e) {
    e.srcElement.effect.target.remove()
  }
}

const toggleVM = () => {
  startVM.value = !startVM.value
}

// Check if day/night and seasonal theme
dayOrNight()

if (process.client) {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js')
  }
}

onMounted(() => {
  offline.value = !navigator.onLine
  menuSelectUpdate()

  // Check if we're in dark mode or not
  try {
    // @TODO: Remove after june
    // Migrate from old cookie
    if (oldDarkApp.value !== undefined && oldDarkApp.value !== null) {
      store.userConfig.darkApp = oldDarkApp.value
      oldDarkApp.value = null
    }

    // If no setting and system is in dark mode, enable dark mode
    if ((store.userConfig.darkApp === null || store.userConfig.darkApp === undefined) &&
        window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      store.userConfig.darkApp = true
    }

    if (store.userConfig.darkApp === true) {
      // Set theme for Twitter widgets
      document.querySelector('meta[name="twitter:widgets:theme"]').setAttribute('content', 'dark')
    }
  } catch (e) {
    console.log('Error getting darkApp.', e)
  }

  // Check if webappp
  if (window.navigator.standalone ||
      window.matchMedia('(display-mode: standalone)').matches) {
    document.querySelector('body').classList.add('webapp')
  }

  // Check if we enable/disable night theme every 15 minutes
  setInterval(dayOrNight, 15 * 60 * 1000)

  // Detect online/offline
  document.body.addEventListener('offline', () => { offline.value = true })
  document.body.addEventListener('online', () => { offline.value = false })

  // Listen for messages
  window.addEventListener('message', (e) => {
    // Resize tweet iframes
    if (e.origin === 'https://twitframe.com') {
      document.querySelector('#' + e.data.element).height = e.data.height
    }
  })

  // Load secu
  const secu = document.createElement('script')
  secu.setAttribute('src', '/js/secu.js')
  document.body.appendChild(secu)

  // Load Shynet
  const shy = document.createElement('script')
  shy.setAttribute('src', 'https://shy.ponyfrance.net/ingress/0a3d3d2f-de9b-43dc-a283-65169cb0197b/script.js')
  document.body.appendChild(shy)

  // Load Twitter
  const twt = document.createElement('script')
  twt.setAttribute('src', '/js/twitter.js')
  document.body.appendChild(twt)

  // Make our Linux "VM" toggle available globally
  window.toggleVM = toggleVM
})

useHead({
  titleTemplate: (title) => (
    route.meta.title ? `${route.meta.title} | Radio Brony` : (
      title ? `${title} | Radio Brony` : 'Radio Brony'
    )
  ),
  title: null, // undefined is apparently not an option
  link: [
    { rel: 'canonical', href: `https://radiobrony.fr${route.fullPath}` },
    { rel: 'manifest', href: '/site.webmanifest' }
  ],
  meta: [
    { name: 'author', content: `${(route.meta.author ? route.meta.author : 'Radio Brony')}` },
    { name: 'description', content: `${(route.meta.description ? route.meta.description : 'La webradio des Bronies, 20% plus baguette')}` },
    { name: 'twitter:card', content: `${(route.meta.player ? 'player' : (route.meta.image ? 'summary_large_image' : 'summary'))}` },
    { name: 'twitter:title', content: `${(route.meta.title ? route.meta.title : 'Radio Brony')}` },
    { name: 'twitter:description', content: `${(route.meta.description ? route.meta.description : 'La webradio des Bronies, 20% plus baguette')}` },
    { name: 'twitter:image', content: `${(route.meta.image ? route.meta.image : 'https://radiobrony.fr/img/icons/bwavatar-details.png')}` },
    { name: 'twitter:image:alt', content: `${(route.meta.title ? route.meta.title : 'Radio Brony')}` },
    { property: 'og:type', content: `${(route.meta.player ? 'article' : 'website')}` },
    { property: 'og:title', content: `${(route.meta.title ? route.meta.title : 'Radio Brony')}` },
    { property: 'og:description', content: `${(route.meta.description ? route.meta.description : 'La webradio des Bronies, 20% plus baguette')}` },
    { property: 'og:url', content: `https://radiobrony.fr${route.fullPath}` },
    { property: 'og:image', content: `${(route.meta.image ? route.meta.image : 'https://radiobrony.fr/img/icons/bwavatar-details.png').replace('https:', 'http:')}` },
    { property: 'og:image:secure_url', content: `${(route.meta.image ? route.meta.image : 'https://radiobrony.fr/img/icons/bwavatar-details.png')}` }
  ],
  bodyAttrs: {
    class: bodyClasses
  }
})

watch(route, () => {
  menuSelectUpdate()
})
</script>

<style lang="scss">
// App styles
@import '@/assets/app';
// Dark overrides
@import '@/assets/dark-app';
</style>
