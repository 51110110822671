import revive_payload_client_frj8aVDMoM from "/var/www/rb-nuxt-test/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.13_ioredis@5.4.1_magicast@0.3.4_rollup@4._3q5uvwrfnj7pipasqw475ps63q/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_jYoOUXSCml from "/var/www/rb-nuxt-test/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.13_ioredis@5.4.1_magicast@0.3.4_rollup@4._3q5uvwrfnj7pipasqw475ps63q/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_yPzGeoMNgu from "/var/www/rb-nuxt-test/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.13_ioredis@5.4.1_magicast@0.3.4_rollup@4._3q5uvwrfnj7pipasqw475ps63q/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_fOtvfbJQms from "/var/www/rb-nuxt-test/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.13_ioredis@5.4.1_magicast@0.3.4_rollup@4._3q5uvwrfnj7pipasqw475ps63q/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_8vRAE1HE3s from "/var/www/rb-nuxt-test/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.13_ioredis@5.4.1_magicast@0.3.4_rollup@4._3q5uvwrfnj7pipasqw475ps63q/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_j1vOPAFM9t from "/var/www/rb-nuxt-test/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.13_ioredis@5.4.1_magicast@0.3.4_rollup@4._3q5uvwrfnj7pipasqw475ps63q/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_ZSJQIxZl9F from "/var/www/rb-nuxt-test/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.13_ioredis@5.4.1_magicast@0.3.4_rollup@4._3q5uvwrfnj7pipasqw475ps63q/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_o5FiJI4FCe from "/var/www/rb-nuxt-test/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.13_ioredis@5.4.1_magicast@0.3.4_rollup@4._3q5uvwrfnj7pipasqw475ps63q/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_v6PurJfVyI from "/var/www/rb-nuxt-test/node_modules/.pnpm/@pinia+nuxt@0.5.2_magicast@0.3.4_rollup@4.19.1_vue@3.4.35/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/rb-nuxt-test/.nuxt/components.plugin.mjs";
import prefetch_client_HfeYH6NvTA from "/var/www/rb-nuxt-test/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.13_ioredis@5.4.1_magicast@0.3.4_rollup@4._3q5uvwrfnj7pipasqw475ps63q/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_z3qoDQIK4N from "/var/www/rb-nuxt-test/node_modules/.pnpm/vue-view-transitions@1.2.1_magicast@0.3.4_nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14_6jlc4ku2ukhfqgojhaerlqzare/node_modules/vue-view-transitions/dist/plugin.js";
import plugin_IKwd5SBjcZ from "/var/www/rb-nuxt-test/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.2_magicast@0.3.4_rollup@4.19.1_vue@3._4gyta43wf3tlovmwotkma2iyz4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import banner_client_QxdrQgwKXW from "/var/www/rb-nuxt-test/plugins/banner.client.ts";
import lite_youtube_client_oDpElJHFZ1 from "/var/www/rb-nuxt-test/plugins/lite-youtube.client.ts";
import sentry_client_shVUlIjFLk from "/var/www/rb-nuxt-test/plugins/sentry.client.ts";
import websocket_client_IwtQxfNJbn from "/var/www/rb-nuxt-test/plugins/websocket.client.ts";
export default [
  revive_payload_client_frj8aVDMoM,
  unhead_jYoOUXSCml,
  router_yPzGeoMNgu,
  payload_client_fOtvfbJQms,
  navigation_repaint_client_8vRAE1HE3s,
  check_outdated_build_client_j1vOPAFM9t,
  view_transitions_client_ZSJQIxZl9F,
  chunk_reload_client_o5FiJI4FCe,
  plugin_vue3_v6PurJfVyI,
  components_plugin_KR1HBZs4kY,
  prefetch_client_HfeYH6NvTA,
  plugin_z3qoDQIK4N,
  plugin_IKwd5SBjcZ,
  banner_client_QxdrQgwKXW,
  lite_youtube_client_oDpElJHFZ1,
  sentry_client_shVUlIjFLk,
  websocket_client_IwtQxfNJbn
]