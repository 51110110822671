<template>
  <div class="vmquit"><a class="btn" href="#" onclick="window.toggleVM()">✕</a></div>
  <div ref="logsEl" class="vmlogs"><span class="p">_</span></div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

// @TODO: Clean this code..?
//        It took me too much time to get the text, too lazy to do things proper

const logsEl = ref(null)
const kLogs = ref(null)
const dLogs = ref(null)

onMounted(() => {
  const bars = ['/', '-', '\\', '|']
    let li = 0

  const loadingStuff = setInterval(() => {
    if (logsEl.value === null) return

    logsEl.value.innerHTML = bars[li]
    li++
    if (li >= bars.length) li = 0
  }, 100)

  const errMsg = (err) => {
    clearInterval(loadingStuff)
    if (logsEl.value === null) return

    logsEl.value.innerHTML = 'Oh no, something went wrong. :(' +
      `<pre>${err}</pre>` +
      '<span class="p">System crashed...</span><br/><br/>' +
      '<a class="btn" href="#" onclick="window.toggleVM()">Shut down</a>'
  }

  fetch('/linux/dmesg.txt')
    .then((rd) => {
      if (!rd.ok) throw Error(rd.statusText)
      return rd.text()
    })
    .then((rt) => {
      kLogs.value = rt

      fetch('/linux/journalctl.txt')
        .then((dd) => {
          if (!dd.ok) throw Error(dd.statusText)
          return dd.text()
        })
        .then((dt) => {
          dLogs.value = dt

          // Let it load 2 more seconds...
          setTimeout(() => {
            clearInterval(loadingStuff)
            if (logsEl.value === null) return

            logsEl.value.innerHTML = ''
            runLogs()
          }, 2000)
        })
        .catch(errMsg)
    })
    .catch(errMsg)
})

const runLogs = () => {
  function pad (nbr) {
    if (nbr < 10) return '0' + nbr
    return nbr
  }

  const now = new Date()
  const date = now.getUTCFullYear() + '-' + pad(now.getUTCMonth() + 1) + '-' + pad(now.getUTCDate())
  let time = pad(now.getUTCHours()) + ':' + pad(now.getUTCMinutes()) + ':' + pad(now.getUTCSeconds())
  kLogs.value = kLogs.value.replace('{rtctime}', `RTC time: ${time}, date: ${date}`)

  // 10 seconds delay until next message
  now.setSeconds(now.getSeconds() + 10)
  time = pad(now.getUTCHours()) + ':' + pad(now.getUTCMinutes()) + ':' + pad(now.getUTCSeconds())
  kLogs.value = kLogs.value.replace('{datetime}', `${date}T${time} UTC (${now.getTime()})`)

  const kLines = kLogs.value.split('\n')
  const dLines = dLogs.value.split('\n')
  const kRegex = /^\[([ 0-9.]+)\] /

  let ki = 0
  let di = 0
  let regMatch
  let lastTs = 0

  // Start our loops
  setTimeout(dumpKLine, 100)

  function dumpKLine () {
    const line = kLines[ki]
    let delay = 0

    if ((regMatch = kRegex.exec(line)) !== null) {
      const ts = parseFloat(regMatch[1]) * 1000

      if (lastTs !== ts) {
        delay = ts - lastTs
        lastTs = ts
      }
    }

    if (ki++ < kLines.length) {
      setTimeout(_ => {
        if (logsEl.value === null) return

        const logEl = document.createElement('div')
        logEl.innerHTML = line
        logsEl.value.appendChild(logEl)

        logsEl.value.scrollTop = logsEl.value.scrollHeight - logsEl.value.clientHeight
        dumpKLine()
      }, delay)
    } else {
      dumpDLine()
    }
  }

  function dumpDLine () {
    if (logsEl.value === null) return

    let line = dLines[di]
    let delay = 10

    if (line) {
      if (line.startsWith(']/w')) {
        // Set a delay
        delay = parseInt(line.replace(']/w ', '')) * 5
      } else if (line.startsWith(']/font')) {
        // Set the font
        logsEl.value.classList.add('font')
      } else if (line.startsWith(']/clear')) {
        // Clear
        logsEl.value.innerHTML = ''
      } else {
        const logEl = document.createElement('div')

        line = line.replace('[  OK  ]', '[  <span class="g">OK</span>  ]')
        line = line.replace(' | ', ' <span class="w">')
        line = line.replace(' |.', '</span>.')

        logEl.innerHTML = line
        logsEl.value.appendChild(logEl)

        logsEl.value.scrollTop = logsEl.value.scrollHeight - logsEl.value.clientHeight
      }
    }

    if (di++ < kLines.length) {
      setTimeout(dumpDLine, delay)
    }
  }
}
</script>

<style lang="scss">
.vmquit {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000001;

  .btn {
    line-height: 1.4em;
  }
}

.vmlogs {
  background-color: black;
  color: lightgray;
  cursor: default;
  font-family: monospace;
  height: calc(100vh - 10px);
  left: 0;
  overflow: hidden;
  padding: env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px) calc(env(safe-area-inset-bottom, 0px) + 10px) env(safe-area-inset-left, 0px);
  position: fixed;
  top: 0;
  white-space: pre-wrap;
  width: 100vw;
  z-index: 1000000;

  &.font {
    font-family: 'Hack', 'Hack NF', 'Hack Nerd Font', 'Fira Code', 'Fira Code NF', 'Fira Code Nerd Font', 'Fira Mono', 'Lucida Console', 'Monaco', Courier, monospace;
    font-size: 0.8em;
  }

  .w {
    font-weight: bold;
    color: white;
  }

  .g {
    color: green;
  }

  .p {
    -webkit-animation: cursor-blink 1s step-end infinite;
    animation: cursor-blink 1s step-end infinite;
  }

  .btn {
    height: auto;
  }
}

@-webkit-keyframes cursor-blink {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@keyframes cursor-blink {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}
</style>
