/* global persistedState */
import { defineStore } from 'pinia'

function tr (state, message, npc) {
  // Hide data, let the animation end, set value and display it again
  if (state.dataDisplay.npc !== npc) {
    if (state.dataDisplay.temp !== npc) {
      // This locks for double-transitions
      state.dataDisplay.temp = npc

      setTimeout(() => {
        state.dataDisplay.showNP = false

        setTimeout(() => {
          state.tracks = message.tracks
          state.meta = message.meta
          state.dataDisplay.showNP = true
          state.dataDisplay.npc = npc
        }, 600)

        if ('mediaSession' in navigator &&
            navigator.mediaSession.metadata !== null &&
            state.dataDisplay.playing === true) {
          navigator.mediaSession.metadata.title = message.tracks.current.title.title
          navigator.mediaSession.metadata.artist = message.tracks.current.title.artist
        }
      }, state.dataDisplay.delay)
    }
  } else {
    state.tracks = message.tracks
    state.meta = message.meta
  }
}

export const useStore = defineStore('main', {
  state: () => {
    return {
      i: 0,
      serverDelay: 0,
      socket: {
        isConnected: false,
        reconnectError: false
      },
      tracks: {
        previous: {},
        current: {
          title: {
            artist: 'Bienvenue',
            title: 'sur Radio Brony'
          },
          elapsed: {
            string: '00:00',
            seconds: 0
          },
          duration: {
            string: '00:00',
            seconds: 0
          },
          start: {
            string: '00:00:00',
            ts: 0
          },
          end: {
            string: '00:00:00',
            ts: 0
          }
        },
        next: {}
      },
      meta: {
        cover: 'https://radiobrony.fr/img/logos/RBLogo.png',
        covers: {
          orig: 'https://t.radiobrony.fr/JnZ_Cc6PQ_NHVjlDi46Y83EjApY=/https://radiobrony.fr/img/logos/RBLogo.png',
          small: 'https://t.radiobrony.fr/haTYp79hNIMql976V1eGmp3cOlw=/0x140/https://radiobrony.fr/img/logos/RBLogo.png',
          medium: 'https://t.radiobrony.fr/9emHFxP5KnVaQAlzsqz4mgY3G88=/0x280/https://radiobrony.fr/img/logos/RBLogo.png',
          large: 'https://t.radiobrony.fr/yXleDDxKkgtTx1wZRu7WmPz3iok=/0x420/https://radiobrony.fr/img/logos/RBLogo.png'
        },
        status: null,
        listeners: 9001,
        team: []
      },
      live: {
        html: '<i>Chargement...</i>'
      },
      dataDisplay: {
        npc: '',
        temp: '',
        firstLoading: true,
        showNP: true,
        playing: false,
        delay: 5000
      },
      userConfig: {
        darkApp: null,
        playerVolume: 0.25
      }
    }
  },
  persist: {
    paths: ['userConfig'],
    storage: persistedState.cookiesWithOptions({
      sameSite: 'strict'
    })
  },
  actions: {
    setDelay (ms) {
      // Buffer delay + delay with server time + internal 5s delay
      this.dataDisplay.delay = ms - this.serverDelay + 5000
    },
    SOCKET_ONOPEN (_) {
      this.socket.isConnected = true
    },
    SOCKET_ONCLOSE (_) {
      this.socket.isConnected = false
    },
    SOCKET_ONERROR (event) {
      console.error('Socket error', event, this)
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE (message) {
      if ('ts' in message) {
        this.serverDelay = message.ts - Date.now()
        console.log('Current time difference + latency with server (in ms):', this.serverDelay)
      } else {
        const npc = message.tracks.current.title.artist + ' - ' + message.tracks.current.title.title

        if (this.tracks !== message.tracks || this.meta !== message.meta) {
          tr(this, message, npc)
        }

        if (this.live !== message.live) {
          this.live = message.live
        }
      }
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT (count) {
      console.info('Socket reconnect, try #' + count, this)
    },
    SOCKET_RECONNECT_ERROR () {
      this.socket.reconnectError = true
    }
  }
})
