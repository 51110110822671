<template>
  <div ref="radioEl" class="radio">
    <div class="player">
      <div class="cover">
        <transition name="fade">
          <img
            :key="store.meta.covers.large"
            alt="Radio Brony"
            :srcset="`${store.meta.covers.small}, ${store.meta.covers.medium} 2x, ${store.meta.covers.large} 3x`"
            :src="store.meta.covers.large"/>
        </transition>
      </div>

      <div class="progress">
        <div
          :style="{ width: playerStatus.progress + '%' }"
          class="bar" />
      </div>

      <div class="liverb-player">
        <div class="ctrl">
          <span
            v-if="!store.dataDisplay.playing && !playerStatus.buffering"
            class="play-stop"
            @click="playerPlay()">
            <PlayIcon title="Lancer la lecture"/>
          </span>
          <span
            v-else-if="playerStatus.buffering"
            class="buffering">
            <TimerSandEmptyIcon title="Chargement..."/>
          </span>
          <span
            v-else
            class="play-stop"
            @click="playerStop()">
            <StopIcon title="Arrêter la lecture"/>
          </span>
          <span
            v-if="!playerStatus.muted"
            class="mute"
            @click="playerMute()">
            <VolumeHighIcon title="Couper le son"/>
          </span>
          <span
            v-if="playerStatus.muted"
            class="mute"
            @click="playerUnmute()">
            <VolumeMuteIcon title="Activer le son"/>
          </span>
        </div>
        <div class="vol">
          <input
            v-model="store.userConfig.playerVolume"
            aria-label="Volume" type="range"
            min="0" max="1" step="0.05"
            @input="playerVolume">
        </div>
      </div>
    </div>
    <transition appear name="slide">
      <div v-if="store.dataDisplay.showNP" class="text">
        <transition name="slide">
          <div
            v-if="store.meta.status"
            class="status"
            v-text="store.meta.status" />
        </transition>
        <div
          class="artist"
          v-text="store.tracks.current.title.artist" />
        <div
          class="title"
          v-text="store.tracks.current.title.title" />
      </div>
    </transition>
  </div>
</template>

<script setup>
/* global MediaMetadata */
// Vue
import { ref, onMounted, onUnmounted, inject } from 'vue'
import { useStore } from '~/store/store'

// Deps
import videojs from 'video.js'

// Icons
import PlayIcon from '@mkody/vue-material-design-icons/Play.vue'
import StopIcon from '@mkody/vue-material-design-icons/Stop.vue'
import TimerSandEmptyIcon from '@mkody/vue-material-design-icons/TimerSandEmpty.vue'
import VolumeHighIcon from '@mkody/vue-material-design-icons/VolumeHigh.vue'
import VolumeMuteIcon from '@mkody/vue-material-design-icons/VolumeMute.vue'

const store = useStore()

let socket
if (process.client) {
  socket = inject('socket')
}

const radioEl = ref(null)
const aObj = ref(null)
const vjs = ref(null)
const playerStatus = ref({
  playing: false,
  buffering: false,
  muted: false,
  prevVol: store.userConfig.playerVolume,
  progress: 0,
  skipHls: false
})
const progressInterval = ref(null)

onUnmounted(() => {
  // Clear interval
  if (progressInterval.value) clearInterval(progressInterval.value)

  // Dispose VideoJS if loaded
  if (vjs.value && !vjs.value.isDisposed()) {
    vjs.value.dispose()
  } else {
    aObj.value.src = null
  }
})

onMounted(() => {
  // Start marquee and interval for progress display
  marquee('artist')
  marquee('title')
  progressInterval.value = setInterval(updateProgress, 1000)

  // Create our audio element
  aObj.value = document.createElement('audio')
  aObj.value.preload = 'none'
  aObj.value.volume = store.userConfig.playerVolume

  // Catch errors when HLS fails
  videojs.hook('error', (_, err) => {
    if ('code' in err && err.code === 4 && !playerStatus.value.skipHls) switchToIcecast()
  })
  videojs.log.level('error') // show only error messages and suppress others

  // Create our VideoJS object
  vjs.value = videojs(aObj.value, {
    html5: {
      vhs: {
        useNetworkInformationApi: true,
        useBandwidthFromLocalStorage: true
      }
    },
    sources: [
      {
        src: 'https://radiobrony.live/hls/live.m3u8',
        type: 'application/x-mpegURL'
      }
    ]
  })

  // Catch when HLS fails too much
  vjs.value.on('vhs-error-reload-canceled', switchToIcecast)

  document.addEventListener('playRadio', playerPlay, false)
  document.addEventListener('plyr:play', playerStop, false)

  const getTS = setInterval(() => {
    if (socket.readyState === 1) {
      clearInterval(getTS)
      // Get server time to find delay
      socket.send('srvtime')
    }
  }, 100)
})

const updateProgress = () => {
  if (store.tracks.current.duration.seconds <= 0 || store.meta.status === 'LIVE' || store.meta.status === 'INFOS') {
    playerStatus.value.progress = 0
    return
  }

  // Not sure if we need to apply serverDelay here too?
  const progress = ((Date.now() + store.serverDelay - store.tracks.current.start.ts - store.dataDisplay.delay) / (store.tracks.current.end.ts - store.tracks.current.start.ts)) * 100
  playerStatus.value.progress = progress >= 100 ? 100 : progress <= 0 ? 0 : progress
}

const switchToIcecast = () => {
  console.log('Playback error, switching to native + icecast')

  // Dispose of VideoJS
  if (!vjs.value.isDisposed()) vjs.value.dispose()
  playerStatus.value.skipHls = true

  // Recreate our audio element
  aObj.value = document.createElement('audio')
  aObj.value.volume = store.userConfig.playerVolume

  // Switch to normal stream
  if (aObj.value.canPlayType('audio/aac') !== '') {
    console.log('Setting aac')
    aObj.value.src = 'https://radiobrony.live/aac'
  } else if (aObj.value.canPlayType('audio/mp3') !== '') {
    console.log('Setting mp3')
    aObj.value.src = 'https://radiobrony.live/mp3'
  } else {
    console.log('Setting ogg')
    aObj.value.src = 'https://radiobrony.live/ogg'
  }

  // Retry playing
  playerPlay()
}


const playerPlay = () => {
  const isHls = !playerStatus.value.skipHls
  const startEvent = new Event('live:play')
  document.dispatchEvent(startEvent)

  const dS = Date.now()
  playerStatus.value.buffering = true

  if (playerStatus.value.skipHls) {
    aObj.value.load()
  } else if (!vjs.value.isDisposed() && vjs.value.liveTracker.isTracking()) {
    vjs.value.liveTracker.seekToLiveEdge()
  }

  const pP = aObj.value.play()

  if (pP !== undefined) {
    pP
      .then(() => {
        aObj.value.volume = store.userConfig.playerVolume
        playerStatus.value.buffering = false
        store.dataDisplay.playing = true
        store.setDelay(Date.now() - dS)

        try { // Apparently FF returns true and then the code crashes
          if ('mediaSession' in navigator) {
            navigator.mediaSession.metadata = new MediaMetadata({
              title: store.tracks.current.title.title,
              artist: store.tracks.current.title.artist,
              album: 'Radio Brony',
              artwork: [{ src: '/img/icons/bw-off.png' }]
            })

            navigator.mediaSession.setActionHandler('play', playerPlay)
            navigator.mediaSession.setActionHandler('pause', playerStop)
            navigator.mediaSession.setActionHandler('seekbackward', null)
            navigator.mediaSession.setActionHandler('seekforward', null)
            navigator.mediaSession.setActionHandler('seekto', null)
          }
        } catch (_) {
          // Silently ignore
        }
      })
      .catch((e) => {
        console.log('Could not start playing:', e)
        if (!isHls) playerStatus.value.buffering = false
        store.dataDisplay.playing = false
      })
  } else {
    aObj.value.volume = store.userConfig.playerVolume
    playerStatus.value.buffering = false
    store.dataDisplay.playing = true
    store.setDelay(Date.now() - dS)
  }
}
const playerStop = () => {
  if (!playerStatus.value.buffering) {
    if (playerStatus.value.skipHls || !vjs.value.isDisposed()) {
      aObj.value.pause()
    } else {
      // It's broken
      console.log('Tried to stop but does the player exist?')
      return
    }
    store.dataDisplay.playing = false
  }
}
const playerMute = () => {
  playerStatus.value.prevVol = store.userConfig.playerVolume
  store.userConfig.playerVolume = 0
  playerStatus.value.muted = true
  aObj.value.volume = 0
}
const playerUnmute = () => {
  playerStatus.value.muted = false
  store.userConfig.playerVolume = playerStatus.value.prevVol
  aObj.value.volume = store.userConfig.playerVolume
}
const playerVolume = () => {
  aObj.value.volume = store.userConfig.playerVolume
  playerStatus.value.muted = (store.userConfig.playerVolume < 0.05)
}

const marquee = (name) => {
  let off = 0
  let cooloff = 0

  function scroll () {
    // If object doesn't exists, skip
    if (!radioEl && !radioEl.value) return

    const tEl = radioEl.value.querySelector('.radio .text')
    const el = radioEl.value.querySelector('.radio .' + name)

    // If elements don't exist, skip
    if (!tEl || !el) return

    const width = el.offsetWidth
    const pWidth = tEl.offsetWidth

    if (width > pWidth) {
      if (cooloff > 0) {
        cooloff--
      } else if (-off > width) {
        off = pWidth
      } else if (-off === 1) {
        cooloff = 800 // 8 seconds
        off--
      } else {
        el.style.marginLeft = off + 'px'
        off--
      }
    } else {
      off = 0
      cooloff = 0
      el.style.marginLeft = 0
    }

    requestAnimationFrame(scroll)
  }

  requestAnimationFrame(scroll)
}
</script>

<style lang="scss" scoped>
body.night {
  .radio {
    background-image: url('../assets/night.ban.jpg');
    background-position: top right;
  }
}

body.xmas,
body.xmas #app.dark-app {
  .radio {
    background-image: url('../assets/xmas.ban.jpg');
    background-position: top right;

    .progress {
      .bar {
        background-color: #FFD700;
      }
    }

    .liverb-player {
      input[type=range] {
        &::-webkit-slider-runnable-track {
          background: #FFD700;
        }

        &::-webkit-slider-thumb {
          background: #fff;
        }

        &::-moz-range-track {
          background: #FFD700;
        }

        &::-moz-range-thumb {
          background: #fff;
        }

        &::-ms-fill-lower, &::-ms-fill-upper {
          background: #FFD700;
        }

        &::-ms-thumb {
          background: #fff;
        }

        &:focus {
          &::-ms-fill-lower {
            background: #FFD700;
          }
        }
      }
    }
  }
}

body.halloween #app.dark-app {
  .radio {
    .liverb-player,
    .text div {
      color: #F84C09;
    }

    input[type=range] {
      &::-webkit-slider-runnable-track {
        background: #F84C09;
      }

      &::-moz-range-track {
        background: #F84C09;
      }

      &::-ms-fill-lower,
      &::-ms-fill-upper {
        background: #F84C09;
      }

      &:focus {
        &::-ms-fill-lower {
          background: #F84C09;
        }
      }
    }
  }
}

body.halloween {
  .radio {
    background-image: url('../assets/halloween.ban.jpg');
    background-position: top right;

    .progress {
      .bar {
        background-color: #F84C09;
      }
    }

    .liverb-player,
    .text div {
      background-color: rgba(247, 76, 8, 0.8);
      color: white;
    }

    .liverb-player {
      input[type=range] {
        &::-webkit-slider-runnable-track {
          background: #b9b9b9;
        }

        &::-webkit-slider-thumb {
          background: #fff;
        }

        &::-moz-range-track {
          background: #b9b9b9;
        }

        &::-moz-range-thumb {
          background: #fff;
        }

        &::-ms-fill-lower, &::-ms-fill-upper {
          background: #b9b9b9;
        }

        &::-ms-thumb {
          background: #fff;
        }

        &:focus {
          &::-ms-fill-lower {
            background: #b9b9b9;
          }
        }
      }
    }
  }
}

.radio {
  // background-color: #a6d9f0;
  background-image: url('../assets/day.ban.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #000000;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
  padding: 0 15px 15px;

  .slide-enter-active,
  .slide-leave-active {
    transition: margin-top 1s, opacity .8s;
  }

  .slide-enter-from,
  .slide-leave-to {
    margin-top: -20px;
    opacity: 0;
  }

  .progress {
    width: 100%;

    .bar {
      background-color: #F53B49;
      content: ' ';
      height: 3px;
      transition: width .5s;
    }
  }

  .player {
    align-self: center;
    flex: 0;
    padding-right: 15px;

    .cover {
      display: flex;
      height: 140px;
      justify-content: center;
      margin-bottom: -3px;
      width: 248px;

      img {
        align-self: center;
        max-height: 140px;
        // max-width: 248px;
        position: absolute;
      }
    }

    audio {
      width: 100%;
    }
  }

  .text {
    align-content: center;
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 15px;
    overflow: hidden;

    div {
      align-self: auto;
      background-color: rgba(255, 255, 255, 0.8);
      flex: 0 1 auto;
      font-size: 30px;
      overflow: hidden;
      padding: 2px 5px;
      white-space: nowrap;
    }

    .status {
      color: red;
      font-size: 15px;
      font-weight: bold;
      order: 1;
    }

    .artist {
      font-size: 30px;
      font-weight: bold;
      order: 2;
    }

    .title {
      order: 3;
    }
  }
}

.liverb-player {
  background-color: rgba(255, 255, 255, .8);
  display: flex;

  .ctrl {
    font-size: 25px;
    line-height: 28px;
    margin-bottom: 2px;
    margin-left: 5px;
    text-align: center;
    white-space: nowrap;

    .play-stop,
    .buffering,
    .mute {
      cursor: pointer;
      margin: 0 5px;
    }
  }

  .vol {
    flex-grow: 1;
    margin: 5px 15px 0 10px;
  }
}

input[type=range] {
  -webkit-appearance: none;
  background: none;
  margin: 0;
  width: 100%;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    background: #000000;
    border-radius: 1px;
    border: 0px solid #18d501;
    box-shadow: 0px 0px 1.7px #002200, 0px 0px 0px #003c00;
    cursor: pointer;
    height: 7px;
    width: 100%;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #fff;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, .5);
    box-shadow: 2.6px 2.6px 3.7px rgba(0, 170, 0, 0), 0px 0px 2.6px rgba(0, 195, 0, 0);
    cursor: pointer;
    height: 20px;
    margin-top: -6.5px;
    width: 20px;
  }

  &:focus::-webkit-slider-runnable-track {
    background: #0a0a0a;
  }

  &::-moz-range-track {
    background: #000000;
    border-radius: 1px;
    border: 0px solid #18d501;
    box-shadow: 0px 0px 1.7px #002200, 0px 0px 0px #003c00;
    cursor: pointer;
    height: 7px;
    width: 100%;
  }

  &::-moz-range-thumb {
    background: #fff;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, .5);
    box-shadow: 2.6px 2.6px 3.7px rgba(0, 170, 0, 0), 0px 0px 2.6px rgba(0, 195, 0, 0);
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  &::-ms-track {
    background: transparent;
    border-color: transparent;
    color: transparent;
    cursor: pointer;
    height: 7px;
    width: 100%;
  }

  &::-ms-fill-lower, &::-ms-fill-upper {
    background: #000000;
    border-radius: 2px;
    border: 0px solid #18d501;
    box-shadow: 0px 0px 1.7px #002200, 0px 0px 0px #003c00;
  }

  &::-ms-thumb {
    background: #fff;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, .5);
    box-shadow: 2.6px 2.6px 3.7px rgba(0, 170, 0, 0), 0px 0px 2.6px rgba(0, 195, 0, 0);
    cursor: pointer;
    height: 20px;
    height: 7px;
    width: 20px;
  }

  &:focus {
    &::-ms-fill-lower {
      background: #000000;
    }

    &::-ms-fill-upper {
      background: #0a0a0a;
    }
  }
}

@media (max-width: 719px) {
  .radio {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;

    .progress .bar {
      height: 5px;
    }

    .player {
      max-width: 100%;
      padding: 0;
      width: 248px;

      .cover {
        margin-bottom: -5px;
        max-width: 100%;

        img {
          max-width: calc(100vw - 45px);
        }
      }
    }

    .text {
      flex: initial;
      margin-top: 1em;
    }
  }
}
</style>
