import { defineNuxtPlugin } from '#app'

import VueNativeSock from 'vue-native-websocket-vue3'
import { useStore } from '~/store/store'

export default defineNuxtPlugin((nuxtApp) => {
  const { $pinia } = nuxtApp

  nuxtApp.vueApp.use(
    VueNativeSock,
    'wss://node.radiobrony.fr',
    {
      format: 'json',
      reconnection: true,
      reconnectionDelay: 10000, // 10 seconds
      // @ts-ignore
      store: useStore($pinia)
    }
  )

  nuxtApp.vueApp.provide('socket', nuxtApp.vueApp.config.globalProperties.$socket)
})
